<script lang="ts">
	import type { Address } from "../../../core/schema/Address";
	import cakoviceHeadlineImg from "../assets/images/cakovice.svg";
	import petrinyHeadlineImg from "../assets/images/petriny.svg";
	import cakoviceBranch from "../assets/images/branches/cakovice-branch.jpg?w=496&webp&imagetools";
	import cakoviceBranchSecondary from "../assets/images/branches/cakovice-branch-secondary.jpg?w=496&webp&imagetools";
	import petrinyBranch from "../assets/images/branches/petriny-branch.jpg?w=496&webp&imagetools";
	import petrinyBranchSecondary from "../assets/images/branches/petriny-branch-secondary.jpg?w=496&webp&imagetools";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import Icon from "./Icon.svelte";
	import parking from "../../../core/assets/icons/parking.svg?raw";
	import Link from "./Link.svelte";

	export let withMap = false;
	export let isSmallMargin = false;
	export let noHeadline = false;

	interface Branch {
		name: string;
		icon: string;
		primaryImage: string;
		secondaryImage: string;
		address: Address;
		openingHours: {
			days: string;
			hours: string;
		};
		phone: string;
		mapUrl: string;
		parkingUrl: string;
		mapIframe: string;
	}
	const branches: Branch[] = [
		{
			name: "Čakovice",
			icon: cakoviceHeadlineImg,
			primaryImage: cakoviceBranch,
			secondaryImage: cakoviceBranchSecondary,
			address: {
				street: "Cukrovarská",
				city: "Praha",
				zip: "196 00",
				streetNumber: "15/20",
			},
			openingHours: {
				days: "pondělí až neděle",
				hours: "9:00 - 18:00",
			},
			phone: "+420 775 033 520",
			mapUrl: "https://maps.app.goo.gl/HguREQ3RwdQAod5w7",
			parkingUrl: "https://maps.app.goo.gl/UVugKzCjrWz7Ziw9A",
			mapIframe:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2556.5124607983203!2d14.522822900000001!3d50.1515518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bec5d086ca579%3A0xb593dd48d02d8585!2zU2xhZGvDoSBkw61sbmEgLSDEjGFrb3ZpY2U!5e0!3m2!1scs!2scz!4v1717601843658!5m2!1scs!2scz",
		},
		{
			name: "Petřiny",
			icon: petrinyHeadlineImg,
			primaryImage: petrinyBranch,
			secondaryImage: petrinyBranchSecondary,
			address: {
				street: "Na Petřinách",
				city: "Praha",
				zip: "162 00",
				streetNumber: "82",
			},
			openingHours: {
				days: "pondělí až neděle",
				hours: "9:00 - 18:00",
			},
			phone: "+420 775 033 564",
			mapUrl: "https://maps.app.goo.gl/PB6DcsGexEUH8YiFA",
			parkingUrl: "https://maps.app.goo.gl/wkEbeHcR4joDkKwt9",
			mapIframe:
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.9169588503146!2d14.342137000000001!3d50.087841600000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bbf3b05dc71eb%3A0x7bd17b667c0157ad!2zU2xhZGvDoSBkw61sbmEgLSBQZXTFmWlueQ!5e0!3m2!1scs!2scz!4v1717601888310!5m2!1scs!2scz",
		},
	];

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div class="relative">
	<h2 class="decorative-headline !mb-8 {isSmallMargin ? 'md:!mb-12' : 'md:!mb-16 lg:!mb-20'}" class:hidden={noHeadline}>
		Naše cukrárny
	</h2>
	<div class="flex flex-col items-center justify-center gap-16 md:flex-row md:items-start md:gap-4 lg:gap-8 xl:gap-12">
		{#each branches as branch}
			<div
				class="hover:border-secondaryBlack-250 group flex flex-col gap-4 overflow-hidden rounded-2xl border border-transparent px-4 py-6 transition-colors duration-300 md:max-w-[31rem] md:gap-6 xl:max-w-[34rem] xl:p-6"
			>
				<StaticPicture
					class="mx-auto h-12 w-[13rem] sm:h-16 sm:w-[15rem] md:h-12 lg:h-16 lg:w-[20rem] xl:mb-2 xl:h-20 xl:w-[initial]"
					image={branch.icon}
					alt={`Nápis - ${branch.name}`}
					height={80}
					width={389}
					loading="eager"
				/>
				<div class="relative h-fit w-full overflow-hidden rounded-lg xl:rounded-xl">
					<StaticPicture
						class="h-auto w-full xl:h-[21.5rem]"
						image={branch.primaryImage}
						alt={`Fotografie pobočky Sladké dílny - ${branch.name}`}
						height={344}
						width={496}
						loading="eager"
					/>
					<StaticPicture
						class="absolute inset-0 m-auto h-full w-full opacity-0 transition-opacity duration-300 group-hover:opacity-100"
						image={branch.secondaryImage}
						alt={`Fotografie pobočky Sladké dílny - ${branch.name}`}
						height={344}
						width={496}
						loading="lazy"
					/>
				</div>
				<div class="flex flex-wrap gap-5 sm:justify-between md:justify-start lg:justify-between lg:gap-3 xl:gap-5">
					<div class="flex flex-col">
						<p class="mb-1 font-semibold md:mb-2">Adresa</p>
						<p>{branch.address.street} {branch.address.streetNumber}</p>
						<p>{branch.address.zip} {branch.address.city}</p>
					</div>
					<div class="flex flex-col">
						<p class="mb-1 font-semibold md:mb-2">Otvírací doba</p>
						<p>{branch.openingHours.days}</p>
						<p>{branch.openingHours.hours}</p>
					</div>
					<div class="flex flex-col">
						<p class="mb-1 font-semibold md:mb-2">Kontakty</p>
						<p>{branch.phone}</p>
					</div>
				</div>
				{#if withMap}
					<div>
						<p class="mb-4 font-semibold">Jak se k nám dostanete</p>
						<div class="aspect-video overflow-hidden rounded-md">
							<iframe
								src={branch.mapIframe}
								title={`Adresa parkoviště naší cukrárny ${branch.name}`}
								width="496"
								height="248"
								style="border:0;"
								allowfullscreen={true}
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								class="max-w-full rounded-md"
							></iframe>
						</div>
					</div>
				{/if}
				<div class="flex flex-wrap gap-2">
					<Link
						class="hover:border-primaryRed hover:text-primaryRed transition-colors duration-300"
						variant="secondary"
						asButton
						href={branch.mapUrl}
						target="_blank"
					>
						Navigovat k nám
					</Link>
					<Link
						class="hover:border-primaryRed hover:text-primaryRed transition-colors duration-300"
						variant="secondary"
						asButton
						href={branch.parkingUrl}
						target="_blank"
					>
						Nejbližší parkoviště
						<Icon icon={parking} class="h-[1.1875rem]" />
					</Link>
				</div>
			</div>
		{/each}
	</div>
</div>
